.branding_818 .progressBg {
  background: #87F696;
}

.branding_818 .progress {
  background: linear-gradient(to right, #C5FFCC, #87F696);
}

.branding_818 .progressBg:before {
  background: #30953D;
}

.branding_818 #percents {
  color: #87F696;
}

.branding_powered_text_818 {
  margin-top: 6.48vh;
  font-family: "MyriadPro", sans-serif;
  font-weight: normal;
  letter-spacing: 0.26vw;
  margin-bottom: -1vh;
  font-size: 0.6vw;
}

@media screen and (orientation: portrait) {
  .branding_powered_text_818 {
    margin-top: 2.48vh;
    font-size: calc(0.6vw * 1.9);
    letter-spacing: calc(0.26vw * 1.9);
    margin-bottom: 0;
  }
}
.branding_818 .branding_logo_818 {
  height: auto;
}

.branding_818 .branding_onlyplay_logo_818 {
  width: 53.9%;
}