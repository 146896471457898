.branding_powered_text_808 {
  margin-top: 6.48vh;
  font-family: "Ubuntu bold", sans-serif;
  font-weight: normal;
  letter-spacing: 0.2vw;
  margin-bottom: -1vh;
  font-size: 0.6vw;
  color: white;
}

.branding_logo_808 {
  height: auto;
}

.branding_808 .preloaderLogoBase {
  width: 74%;
}

@media screen and (orientation: portrait) {
  .branding_powered_text_808 {
    margin-top: 2.48vh;
    font-size: calc(0.6vw * 1.9);
    letter-spacing: calc(0.2vw * 1.9);
    margin-bottom: 0;
  }
}